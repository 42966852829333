import _findIndex from 'lodash/findIndex'

const usedTemplatesIds = [
  {
    name: 'coffeeShop',
    value: process.env.TEMPLATE_ID_COFFEE_SHOP,
  },
  {
    name: 'gym',
    value: process.env.TEMPLATE_ID_GYM,
  },
  {
    name: 'groceryStore',
    value: process.env.TEMPLATE_ID_GROCERY_STORE,
  },
  {
    name: 'eCommerce',
    value: process.env.TEMPLATE_ID_E_COMMERCE,
  },
  {
    name: 'techStartup',
    value: process.env.TEMPLATE_ID_TECH_STARTUP,
  },
]

const mergeTemplateIds = (data) => {
  usedTemplatesIds.forEach(({ name, value }) => {
    const index = _findIndex(data, ['name', name])

    if (index !== -1) {
      data[index].pathKey = value
    }
  })

  return data
}

const mergeTemplateIdsAlt = (data) => {
  data.forEach(({ name }, index) => {
    const templateIndex = _findIndex(usedTemplatesIds, ['name', name])

    if (templateIndex !== -1) {
      data[index].pathKey = usedTemplatesIds[templateIndex].value
    }
  })

  return data
}

export { usedTemplatesIds, mergeTemplateIds, mergeTemplateIdsAlt }
