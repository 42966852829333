import React from 'react'
import styled from 'styled-components'
import { breakpoint, color } from '@configs/utilities'
import { GatsbyImage } from 'gatsby-plugin-image'
import UseTemplateLink from '@components/common/UseTemplateLink'

const CustomizableTemplatesCard = (props) => {
  return (
    <Card>
      <ImageWrapper>
        <GatsbyImage image={props.image} alt="customizable template card" />
      </ImageWrapper>
      <TextWrapper>
        <IconText>
          {/*{props.icon && <img src={props.icon} alt={props.iconText} />}*/}
          <span>{props.iconText}</span>
        </IconText>
        <TemplateText>
          <p className={`activeIconText-${props.color}`}>{props.template}</p>
        </TemplateText>
        <UseTemplateLink templateId={props.pathKey} />
      </TextWrapper>
    </Card>
  )
}

export default CustomizableTemplatesCard

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${color.grayDark};
  border-radius: 8px;
`

const ImageWrapper = styled.div`
  border-bottom: 1px solid ${color.grayDark};
  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  row-gap: 10px;
`

const IconText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 18px;
  }

  img {
    margin-right: 10px;
  }
`

const TemplateText = styled.div`
  font-weight: 600;

  .activeIconText-idea-plan {
    color: ${color.ideaPlan};
  }

  .activeIconText-business-guide {
    color: ${color.businessGuide};
  }

  .activeIconText-financial-plan {
    color: ${color.financialPlan};
  }

  .activeIconText-idea-validation {
    color: ${color.ideaValidation};
  }

  .activeIconText-business-plan {
    color: ${color.businessPlan};
  }
`
